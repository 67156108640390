import React from "react"
import { graphql } from 'gatsby'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import Layout from "../components/layout"
import { Hero, HeroText } from "../components/Hero"
import SEO from "../components/seo"
import { Contact, ContactForm } from "../components/contact-us"

const ContactPage = ({ data }) => (
  <Layout>
  {/* <GoogleReCaptchaProvider reCaptchaKey="6LepqqYaAAAAAEMVmP17rcE5S2mYijKtaQGxEBi-"> */}

    <SEO title="Contact" />

    <Hero fluid={data.hero.edges[0].node.fluid}>
      <HeroText text="Contact"/>
    </Hero>

    <div style={{
        display: 'flex',
        flexDirection: 'column',
        flexFlow: 'row wrap',
        alignItems: 'stretch',
        WebkitBoxAlign: 'stretch',
        margin: 'auto',
     }}>
      <Contact width="50%"/>
      <ContactForm width="50%"/>
    </div>

  {/* </GoogleReCaptchaProvider> */}
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query {
    hero: allImageSharp(filter: { original: { src: { regex: "/laptop-3317007_1280/" } } }) {
      edges {
        node {
          id
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;


/*

import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LepqqYaAAAAAEMVmP17rcE5S2mYijKtaQGxEBi-">
            {element}
        </GoogleReCaptchaProvider>
    )
}


*/
